@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

a {
  text-decoration: none;
  color: inherit; }

@-webkit-keyframes init {
  0% {
    width: 0;
    height: 0; }
  100% {
    width: 56px;
    height: 56px;
    margin-top: 0;
    opacity: 1; } }

@keyframes init {
  0% {
    width: 0;
    height: 0; }
  100% {
    width: 56px;
    height: 56px;
    margin-top: 0;
    opacity: 1; } }

@-webkit-keyframes puff {
  0% {
    top: 100%;
    height: 0;
    padding: 0; }
  100% {
    top: 50%;
    height: 100%;
    padding: 0 100%; } }

@keyframes puff {
  0% {
    top: 100%;
    height: 0;
    padding: 0; }
  100% {
    top: 50%;
    height: 100%;
    padding: 0 100%; } }

@-webkit-keyframes borderRadius {
  0% {
    -webkit-border-radius: 50%; }
  100% {
    -webkit-border-radius: 0; } }

@keyframes borderRadius {
  0% {
    -webkit-border-radius: 50%; }
  100% {
    border-radius: 0; } }

@-webkit-keyframes moveDown {
  0% {
    top: 50%; }
  50% {
    top: 40%; }
  100% {
    top: 100%; } }

@keyframes moveDown {
  0% {
    top: 50%; }
  50% {
    top: 40%; }
  100% {
    top: 100%; } }

@-webkit-keyframes moveUp {
  0% {
    background: #ffb300;
    top: 100%; }
  50% {
    top: 40%; }
  100% {
    top: 50%;
    background: #e0e0e0; } }

@keyframes moveUp {
  0% {
    background: #ffb300;
    top: 100%; }
  50% {
    top: 40%; }
  100% {
    top: 50%;
    background: #e0e0e0; } }

@-webkit-keyframes materia {
  0% {
    background: #e0e0e0; }
  50% {
    -webkit-border-radius: 4px; }
  100% {
    width: 440px;
    height: 280px;
    background: #ffffff;
    -webkit-border-radius: 4px; } }

@keyframes materia {
  0% {
    background: #e0e0e0; }
  50% {
    border-radius: 4px; }
  100% {
    width: 440px;
    height: 280px;
    background: #ffffff;
    border-radius: 4px; } }

@-webkit-keyframes moveIn {
  0% {
    margin-top: 50px;
    opacity: 0; }
  100% {
    opacity: 1;
    margin-top: -20px; } }

@keyframes moveIn {
  0% {
    margin-top: 50px;
    opacity: 0; }
  100% {
    opacity: 1;
    margin-top: -20px; } }

@-webkit-keyframes scaleIn {
  0% {
    -webkit-transform: scale(0); }
  100% {
    -webkit-transform: scale(1); } }

@keyframes scaleIn {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale3d(0, 0, 0);
            transform: scale3d(0, 0, 0); }
  50%,
  100% {
    -webkit-transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0; } }

@keyframes ripple {
  0% {
    -webkit-transform: scale3d(0, 0, 0);
            transform: scale3d(0, 0, 0); }
  50%,
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1); }
  100% {
    opacity: 0; } }

@media screen and (min-aspect-ratio: 4 / 3) {
  @-webkit-keyframes puff {
    0% {
      top: 100%;
      width: 0;
      padding-bottom: 0; }
    100% {
      top: 50%;
      width: 100%;
      padding-bottom: 100%; } }
  @keyframes puff {
    0% {
      top: 100%;
      width: 0;
      padding-bottom: 0; }
    100% {
      top: 50%;
      width: 100%;
      padding-bottom: 100%; } } }

@media screen and (min-height: 480px) {
  @-webkit-keyframes materia {
    0% {
      background: #e0e0e0; }
    50% {
      -webkit-border-radius: 4px; }
    100% {
      width: 280px;
      height: 440px;
      background: #ffffff;
      -webkit-border-radius: 4px; } }
  @keyframes materia {
    0% {
      background: #e0e0e0; }
    50% {
      border-radius: 4px; }
    100% {
      width: 280px;
      height: 440px;
      background: #ffffff;
      border-radius: 4px; } } }

html {
  height: 100%; }

#wrapper {
  overflow: hidden;
  background: #313440 no-repeat center center fixed;
  background-size: cover;
  position: fixed;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  font: normal 14px/1.618em 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased; }
  #wrapper:before {
    content: '';
    height: 0;
    padding: 0;
    border: 130em solid #bcdee7;
    position: absolute;
    left: 50%;
    top: 100%;
    z-index: 2;
    display: block;
    border-radius: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-animation: puff 0.5s 1.8s cubic-bezier(0.55, 0.055, 0.675, 0.19) forwards, borderRadius 0.2s 2.3s linear forwards;
    animation: puff 0.5s 1.8s cubic-bezier(0.55, 0.055, 0.675, 0.19) forwards, borderRadius 0.2s 2.3s linear forwards; }
  #wrapper h1,
  #wrapper h2 {
    font-weight: 500;
    margin: 0 0 5px 0; }
  #wrapper h1 {
    font-size: 24px; }
  #wrapper h2 {
    font-size: 16px; }
  #wrapper p {
    margin: 0; }
  #wrapper .profile-card {
    background: #ffb300;
    width: 56px;
    height: 56px;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    overflow: hidden;
    opacity: 0;
    margin-top: 70px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    -webkit-animation: init 0.5s 0.2s cubic-bezier(0.55, 0.055, 0.675, 0.19) forwards, moveDown 1s 0.8s cubic-bezier(0.6, -0.28, 0.735, 0.045) forwards, moveUp 1s 1.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards, materia 0.5s 2.7s cubic-bezier(0.86, 0, 0.07, 1) forwards;
    animation: init 0.5s 0.2s cubic-bezier(0.55, 0.055, 0.675, 0.19) forwards, moveDown 1s 0.8s cubic-bezier(0.6, -0.28, 0.735, 0.045) forwards, moveUp 1s 1.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards, materia 0.5s 2.7s cubic-bezier(0.86, 0, 0.07, 1) forwards; }
  #wrapper .profile-card header {
    width: 179px;
    height: 280px;
    padding: 40px 20px 30px 20px;
    display: inline-block;
    float: left;
    border-right: 2px dashed #eeeeee;
    background: #ffffff;
    color: #000000;
    margin-top: 50px;
    opacity: 0;
    text-align: center;
    -webkit-animation: moveIn 1s 3.1s ease forwards;
    animation: moveIn 1s 3.1s ease forwards; }
  #wrapper .profile-card header h1 {
    color: #ff5722; }
  #wrapper .profile-card header a {
    display: inline-block;
    text-align: center;
    position: relative;
    margin: 25px 30px; }
  #wrapper .profile-card header a .flag {
    position: absolute;
    content: '';
    bottom: 3px;
    right: 3px;
    width: 23px;
    height: 23px;
    border: 4px solid #ffffff;
    -webkit-transform: scale(0);
    transform: scale(0);
    background: linear-gradient(#ff8000 0%, #ff8000 33.33%, #ffffff 33.33%, #ffffff 66.67%, #008000 66.67%, #008000 100%);
    border-radius: 50%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    -webkit-animation: scaleIn 0.3s 3.5s ease forwards;
    animation: scaleIn 0.3s 3.5s ease forwards;
    display: flex;
    justify-content: center;
    align-items: center; }
  #wrapper .ashok-chakra {
    z-index: 1;
    margin: 0; }
    #wrapper .ashok-chakra li {
      display: block;
      width: 1px;
      height: 1px;
      border-width: 2px;
      border-style: solid;
      border-color: #ddd transparent #aaaaaa;
      position: absolute;
      left: 0;
      margin-left: auto;
      right: 0;
      margin-right: auto;
      top: 0;
      margin-top: auto;
      bottom: 0;
      margin-bottom: auto; }
      #wrapper .ashok-chakra li:nth-child(2) {
        -webkit-transform: rotate(30deg);
        transform: rotate(30deg);
        border-color: #0000ff transparent #0000ff; }
      #wrapper .ashok-chakra li:nth-child(3) {
        -webkit-transform: rotate(60deg);
        transform: rotate(60deg);
        border-color: #0000ff transparent #0000ff; }
      #wrapper .ashok-chakra li:nth-child(4) {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        border-color: #0000ff transparent #0000ff; }
      #wrapper .ashok-chakra li:nth-child(5) {
        -webkit-transform: rotate(120deg);
        transform: rotate(120deg);
        border-color: #0000ff transparent #0000ff; }
      #wrapper .ashok-chakra li:nth-child(6) {
        -webkit-transform: rotate(150deg);
        transform: rotate(150deg);
        border-color: #0000ff transparent #0000ff; }
      #wrapper .ashok-chakra li:nth-child(7) {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        border-color: #0000ff transparent #0000ff; }
      #wrapper .ashok-chakra li:nth-child(8) {
        -webkit-transform: rotate(210deg);
        transform: rotate(210deg);
        border-color: #0000ff transparent #0000ff; }
  #wrapper .profile-card header a > img {
    width: 120px;
    max-width: 100%;
    border-radius: 50%;
    transition: box-shadow 0.3s ease;
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.06); }
  #wrapper .profile-card header a:hover > img {
    box-shadow: 0 0 0 12px rgba(0, 0, 0, 0.1); }
  #wrapper .profile-card .profile-bio {
    width: 175px;
    height: 180px;
    display: inline-block;
    float: right;
    padding: 50px 20px 30px 20px;
    background: #ffffff;
    color: #333333;
    margin-top: 50px;
    text-align: center;
    opacity: 0;
    -webkit-animation: moveIn 1s 3.1s ease forwards;
    animation: moveIn 1s 3.1s ease forwards; }
  #wrapper .profile-social-links {
    width: 218px;
    display: flex;
    justify-content: space-around;
    float: right;
    margin: 0;
    padding: 15px 10px;
    background: #ffffff;
    margin-top: 50px;
    text-align: center;
    opacity: 0;
    box-sizing: border-box;
    -webkit-animation: moveIn 1s 3.1s ease forwards;
    animation: moveIn 1s 3.1s ease forwards; }
  #wrapper .profile-social-links li {
    list-style: none;
    margin: -5px 0 0 0;
    padding: 0;
    float: left;
    text-align: center; }
  #wrapper .profile-social-links li a {
    display: inline-block;
    color: red;
    width: 24px;
    height: 24px;
    padding: 6px;
    position: relative; }
  #wrapper .profile-social-links li a i {
    position: relative;
    z-index: 1;
    font-size: 19px; }
  #wrapper .profile-social-links li a img,
  #wrapper .profile-social-links li a svg {
    width: 24px; }
  #wrapper .home-link {
    cursor: pointer;
    transition: all ease-in 0.2s; }
  #wrapper .home-link:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }
  @media screen and (min-aspect-ratio: 4 / 3) {
    #wrapper #wrapper {
      background-size: cover; }
    #wrapper #wrapper:before {
      width: 0; } }
  @media screen and (min-height: 480px) {
    #wrapper .profile-card header {
      width: auto;
      height: auto;
      padding: 30px 20px;
      display: block;
      float: none;
      border-right: none; }
    #wrapper .profile-card .profile-bio {
      width: auto;
      height: auto;
      padding: 15px 20px 30px 20px;
      display: block;
      float: none; }
    #wrapper .profile-social-links {
      width: 100%;
      float: none; } }

.story-card, .story-card-no-image {
  position: relative;
  margin: auto;
  margin-bottom: 110px; }
  .story-card .image-wrapper, .story-card-no-image .image-wrapper {
    width: calc(100% - 200px);
    height: 468px; }
  @media (max-width: 1000px) {
    .story-card, .story-card-no-image {
      margin-bottom: 50px; }
      .story-card .image-wrapper, .story-card-no-image .image-wrapper {
        display: none; }
      .story-card .container, .story-card-no-image .container {
        position: relative;
        width: 100%; }
      .story-card .content, .story-card-no-image .content {
        width: 100%;
        height: auto; } }
  @media (min-width: 1001px) {
    .story-card .container, .story-card-no-image .container {
      position: absolute;
      bottom: -100px;
      right: 0; }
    .story-card .content, .story-card-no-image .content {
      width: 476px;
      max-height: 344px;
      float: right;
      position: relative; } }
  .story-card .content, .story-card .content-no-image, .story-card-no-image .content, .story-card-no-image .content-no-image {
    border-radius: 12px;
    box-sizing: border-box;
    padding: 50px 60px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.16) 0 4px 48px;
    margin-bottom: 40px; }
    .story-card .content .publish-date, .story-card .content-no-image .publish-date, .story-card-no-image .content .publish-date, .story-card-no-image .content-no-image .publish-date {
      position: absolute;
      right: 20px;
      bottom: 20px;
      color: #9c9191;
      font-size: 12px; }
    .story-card .content h1, .story-card .content-no-image h1, .story-card-no-image .content h1, .story-card-no-image .content-no-image h1 {
      font-size: 30px;
      font-weight: 500;
      line-height: 40px;
      margin-bottom: 12px;
      color: #2f3152; }
    .story-card .content p, .story-card .content-no-image p, .story-card-no-image .content p, .story-card-no-image .content-no-image p {
      margin-top: 16px;
      line-height: 28px;
      margin-bottom: 26px;
      color: #757686;
      font-size: 16px; }
    .story-card .content a, .story-card .content-no-image a, .story-card-no-image .content a, .story-card-no-image .content-no-image a {
      font-weight: 500;
      line-height: 24px;
      color: #1090cb;
      text-decoration: none;
      text-transform: uppercase; }
    .story-card .content a:hover, .story-card .content-no-image a:hover, .story-card-no-image .content a:hover, .story-card-no-image .content-no-image a:hover {
      color: #2880a9; }
  .story-card .container-no-image, .story-card-no-image .container-no-image {
    width: 100%; }
  .story-card .content-no-image, .story-card-no-image .content-no-image {
    float: none; }

.story-card-no-image {
  margin-bottom: 50px; }

.cover-image-bg {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: center center / cover; }

.cover-image {
  width: 100%;
  border-radius: 12px; }

.image-credits {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(255, 255, 255, 0.5);
  padding: 5px;
  border-radius: 8px;
  font-size: 6px; }

@charset "UTF-8";
#stories-page {
  font: normal 14px 'Open Sans', sans-serif;
  background: white;
  padding: 20px 50px 50px;
  max-width: 1100px;
  width: 100%;
  margin: auto; }
  @media (max-width: 1300px) {
    #stories-page {
      max-width: 900px; } }
  @media (max-width: 1000px) {
    #stories-page {
      padding: 20px 30px 50px; } }

.header-container {
  margin-bottom: 25px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e8dfdf; }
  .header-container h1 {
    font-weight: 900;
    margin-bottom: 20px; }
  .header-container .header {
    display: flex; }
  .header-container .logo {
    width: 60px;
    margin-right: 20px; }
  .header-container .blog-name {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .header-container .blog-heading {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 5px; }
    .header-container .blog-heading a {
      border-bottom: 1px solid;
      padding-bottom: 1px; }
  .header-container .blog-subheading {
    color: #9c9191; }

.footer-container {
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid #e8dfdf; }
  .footer-container a:not(:last-child):after {
    content: '\2022';
    margin: 0 10px; }

.stories {
  min-height: calc(100vh - 260px); }

.share-container {
  display: flex; }
  .share-container a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #f1f3f5;
    margin-bottom: 10px;
    margin-right: 10px; }
    .share-container a:hover {
      color: white; }
    .share-container a.twitter:hover {
      background: #04aceb; }
    .share-container a.facebook:hover {
      background: #3b5998; }
    .share-container a.email:hover {
      background: #a5b2bd; }

#story-page {
  position: relative;
  display: flex; }
  #story-page h1 {
    font-size: 40px;
    font-weight: 900; }
  #story-page .story-content .story-block {
    display: block;
    -webkit-margin-before: 1em;
            margin-block-start: 1em;
    -webkit-margin-after: 1em;
            margin-block-end: 1em;
    text-align: justify;
    margin: 20px 0;
    letter-spacing: 0.011rem;
    line-height: 32px;
    font-size: 21px; }
    @media (max-width: 1000px) {
      #story-page .story-content .story-block {
        font-size: 19px; } }
    #story-page .story-content .story-block img {
      width: 100%;
      border-radius: 5px; }
    #story-page .story-content .story-block > img {
      margin-top: 40px; }
    #story-page .story-content .story-block .image-wrapper {
      text-align: center;
      width: 100%;
      margin: auto;
      max-width: 500px; }
    #story-page .story-content .story-block small {
      display: block;
      text-align: center;
      font-size: 12px; }
      @media (min-width: 1001px) {
        #story-page .story-content .story-block small {
          height: 12px; } }
  #story-page .story-content a {
    text-decoration: underline; }
  #story-page .cover-image-wrapper {
    width: 50%;
    margin: 10px 20px 15px 0;
    float: left; }
    @media (max-width: 1000px) {
      #story-page .cover-image-wrapper {
        float: none;
        display: flex;
        justify-content: center;
        width: 100%; }
        #story-page .cover-image-wrapper > div {
          width: 100%;
          max-width: 500px;
          margin: auto; } }
  #story-page #publish-date {
    color: #a19796;
    margin-bottom: 10px; }

.share-buttons-desktop {
  position: -webkit-sticky;
  position: sticky;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: -100px;
  margin-right: 50px;
  top: 100px;
  margin-top: 110px; }
  @media (max-width: 1000px) {
    .share-buttons-desktop {
      display: none; } }

.share-buttons-mobile {
  margin-left: -5px; }
  @media (min-width: 1001px) {
    .share-buttons-mobile {
      display: none; } }

@media (min-width: 1001px) {
  .left {
    float: left;
    margin-right: 20px !important; }
  .right {
    float: right;
    margin-left: 20px !important; }
  .mobile {
    display: none; } }

@media (max-width: 1000px) {
  .left, .right {
    display: none; } }

@page {
  size: 7in 9.25in;
  margin: 0; }

.resume-container {
  width: 750px;
  margin: auto; }
  .resume-container a {
    text-decoration: none;
    color: white; }
  .resume-container .header-inner-container {
    padding: 20px 35px 10px; }
  .resume-container .header {
    background: #6a7182;
    color: white; }
  .resume-container .name {
    font-size: 36px;
    font-weight: 700; }
  .resume-container .headline {
    font-size: 15px;
    text-align: justify;
    margin-top: -10px; }
  .resume-container .panel {
    display: flex;
    justify-content: space-around;
    background: rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 10px 0; }
  .resume-container .panel > div {
    display: flex;
    align-items: center;
    font-size: 14px; }
  .resume-container .panel i {
    margin-right: 5px; }
  .resume-container .body {
    display: flex; }
  .resume-container .left-container {
    width: 65%;
    font-size: 0.79rem; }
  .resume-container .right-container {
    border-left: 1px solid #6a7182;
    width: 35%;
    font-size: 0.79rem; }
  .resume-container .inner-container {
    padding: 15px 20px; }
  .resume-container .inner-container.left {
    padding-left: 35px; }
  .resume-container .inner-container.right {
    padding-right: 35px; }
  .resume-container .job {
    margin-bottom: 10px; }
  .resume-container .job-title {
    font-size: 13px;
    font-weight: bold; }
  .resume-container .job-subtitle {
    font-style: italic;
    margin-bottom: 10px; }
  .resume-container .job-assignment-title {
    margin-top: 8px;
    font-weight: bold;
    font-size: 12px; }
  .resume-container .section-heading {
    border-bottom: 1px solid;
    font-size: 16px;
    margin-bottom: 10px;
    padding-bottom: 5px;
    color: #6a7182;
    font-weight: bold;
    text-transform: uppercase; }
  .resume-container .job-summary {
    text-align: justify; }
  .resume-container ul {
    -webkit-padding-start: 20px;
            padding-inline-start: 20px;
    -webkit-margin-before: 8px;
            margin-block-start: 8px; }
  .resume-container li {
    text-align: justify; }
  .resume-container ul ul {
    margin-top: 0; }
  .resume-container .section {
    margin-bottom: 20px; }
  .resume-container .work-experience-section {
    margin-bottom: 10px; }
  .resume-container .education {
    margin-bottom: 5px; }
  .resume-container .degree {
    font-weight: bold;
    text-align: justify; }
  .resume-container .university {
    font-style: italic;
    margin-right: 5px; }
  .resume-container .activity {
    margin-bottom: 10px;
    text-align: justify; }
  .resume-container .skills {
    display: flex;
    flex-wrap: wrap; }
  .resume-container .skill {
    border: 1px solid #697082;
    margin: 5px 5px 5px 0;
    padding: 0 5px;
    border-radius: 4px;
    background: #697082;
    color: white; }

