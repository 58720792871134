html {
  height: 100%;
}

#wrapper {
  overflow: hidden;
  background: #313440 no-repeat center center fixed;
  background-size: cover;
  position: fixed;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  font: normal 14px/1.618em 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;

  &:before {
    content: '';
    height: 0;
    padding: 0;
    border: 130em solid #bcdee7;
    position: absolute;
    left: 50%;
    top: 100%;
    z-index: 2;
    display: block;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-animation: puff 0.5s 1.8s cubic-bezier(0.55, 0.055, 0.675, 0.19)
        forwards,
      borderRadius 0.2s 2.3s linear forwards;
    animation: puff 0.5s 1.8s cubic-bezier(0.55, 0.055, 0.675, 0.19) forwards,
      borderRadius 0.2s 2.3s linear forwards;
  }

  h1,
  h2 {
    font-weight: 500;
    margin: 0 0 5px 0;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 16px;
  }

  p {
    margin: 0;
  }

  .profile-card {
    background: #ffb300;
    width: 56px;
    height: 56px;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    overflow: hidden;
    opacity: 0;
    margin-top: 70px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
      0 3px 6px rgba(0, 0, 0, 0.23);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    -webkit-animation: init 0.5s 0.2s cubic-bezier(0.55, 0.055, 0.675, 0.19)
        forwards,
      moveDown 1s 0.8s cubic-bezier(0.6, -0.28, 0.735, 0.045) forwards,
      moveUp 1s 1.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards,
      materia 0.5s 2.7s cubic-bezier(0.86, 0, 0.07, 1) forwards;
    animation: init 0.5s 0.2s cubic-bezier(0.55, 0.055, 0.675, 0.19) forwards,
      moveDown 1s 0.8s cubic-bezier(0.6, -0.28, 0.735, 0.045) forwards,
      moveUp 1s 1.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards,
      materia 0.5s 2.7s cubic-bezier(0.86, 0, 0.07, 1) forwards;
  }

  .profile-card header {
    width: 179px;
    height: 280px;
    padding: 40px 20px 30px 20px;
    display: inline-block;
    float: left;
    border-right: 2px dashed #eeeeee;
    background: #ffffff;
    color: #000000;
    margin-top: 50px;
    opacity: 0;
    text-align: center;
    -webkit-animation: moveIn 1s 3.1s ease forwards;
    animation: moveIn 1s 3.1s ease forwards;
  }

  .profile-card header h1 {
    color: #ff5722;
  }

  .profile-card header a {
    display: inline-block;
    text-align: center;
    position: relative;
    margin: 25px 30px;
  }

  .profile-card header a .flag {
    position: absolute;
    content: '';
    bottom: 3px;
    right: 3px;
    width: 23px;
    height: 23px;
    border: 4px solid #ffffff;
    -webkit-transform: scale(0);
    transform: scale(0);
    background: -webkit-linear-gradient(
      top,
      #ff8000 0%,
      #ff8000 33.33%,
      #ffffff 33.33%,
      #ffffff 66.67%,
      #008000 66.67%,
      #008000 100%
    );
    background: linear-gradient(
      #ff8000 0%,
      #ff8000 33.33%,
      #ffffff 33.33%,
      #ffffff 66.67%,
      #008000 66.67%,
      #008000 100%
    );
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    -webkit-animation: scaleIn 0.3s 3.5s ease forwards;
    animation: scaleIn 0.3s 3.5s ease forwards;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ashok-chakra {
    z-index: 1;
    margin: 0;

    li {
      display: block;
      width: 1px;
      height: 1px;
      border-width: 2px;
      border-style: solid;
      border-color: #ddd transparent #aaaaaa;
      position: absolute;
      left: 0;
      margin-left: auto;
      right: 0;
      margin-right: auto;
      top: 0;
      margin-top: auto;
      bottom: 0;
      margin-bottom: auto;

      &:nth-child(2) {
        -moz-transform: rotate(30deg);
        -ms-transform: rotate(30deg);
        -webkit-transform: rotate(30deg);
        transform: rotate(30deg);
        border-color: #0000ff transparent #0000ff;
      }
      &:nth-child(3) {
        -moz-transform: rotate(60deg);
        -ms-transform: rotate(60deg);
        -webkit-transform: rotate(60deg);
        transform: rotate(60deg);
        border-color: #0000ff transparent #0000ff;
      }
      &:nth-child(4) {
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        border-color: #0000ff transparent #0000ff;
      }
      &:nth-child(5) {
        -moz-transform: rotate(120deg);
        -ms-transform: rotate(120deg);
        -webkit-transform: rotate(120deg);
        transform: rotate(120deg);
        border-color: #0000ff transparent #0000ff;
      }
      &:nth-child(6) {
        -moz-transform: rotate(150deg);
        -ms-transform: rotate(150deg);
        -webkit-transform: rotate(150deg);
        transform: rotate(150deg);
        border-color: #0000ff transparent #0000ff;
      }
      &:nth-child(7) {
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        border-color: #0000ff transparent #0000ff;
      }
      &:nth-child(8) {
        -moz-transform: rotate(210deg);
        -ms-transform: rotate(210deg);
        -webkit-transform: rotate(210deg);
        transform: rotate(210deg);
        border-color: #0000ff transparent #0000ff;
      }
    }
  }

  .profile-card header a > img {
    width: 120px;
    max-width: 100%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: -webkit-box-shadow 0.3s ease;
    transition: box-shadow 0.3s ease;
    -webkit-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.06);
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.06);
  }

  .profile-card header a:hover > img {
    -webkit-box-shadow: 0 0 0 12px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 12px rgba(0, 0, 0, 0.1);
  }

  .profile-card .profile-bio {
    width: 175px;
    height: 180px;
    display: inline-block;
    float: right;
    padding: 50px 20px 30px 20px;
    background: #ffffff;
    color: #333333;
    margin-top: 50px;
    text-align: center;
    opacity: 0;
    -webkit-animation: moveIn 1s 3.1s ease forwards;
    animation: moveIn 1s 3.1s ease forwards;
  }

  .profile-social-links {
    width: 218px;
    display: flex;
    justify-content: space-around;
    float: right;
    margin: 0;
    padding: 15px 10px;
    background: #ffffff;
    margin-top: 50px;
    text-align: center;
    opacity: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-animation: moveIn 1s 3.1s ease forwards;
    animation: moveIn 1s 3.1s ease forwards;
  }

  .profile-social-links li {
    list-style: none;
    margin: -5px 0 0 0;
    padding: 0;
    float: left;
    text-align: center;
  }

  .profile-social-links li a {
    display: inline-block;
    color: red;
    width: 24px;
    height: 24px;
    padding: 6px;
    position: relative;
  }

  .profile-social-links li a i {
    position: relative;
    z-index: 1;
    font-size: 19px;
  }

  .profile-social-links li a img,
  .profile-social-links li a svg {
    width: 24px;
  }

  .home-link {
    cursor: pointer;
    transition: all ease-in 0.2s;
  }

  .home-link:hover {
    transform: scale(1.2);
  }

  @media screen and (min-aspect-ratio: 4/3) {
    #wrapper {
      background-size: cover;
    }
    #wrapper:before {
      width: 0;
    }
  }

  @media screen and (min-height: 480px) {
    .profile-card header {
      width: auto;
      height: auto;
      padding: 30px 20px;
      display: block;
      float: none;
      border-right: none;
    }
    .profile-card .profile-bio {
      width: auto;
      height: auto;
      padding: 15px 20px 30px 20px;
      display: block;
      float: none;
    }
    .profile-social-links {
      width: 100%;
      float: none;
    }
  }
}
