.share-container {
  display: flex;

  a {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: rgb(241, 243, 245);
    margin-bottom: 10px;
    margin-right: 10px;

    &:hover {
      color: white;
    }

    &.twitter:hover {
      background: rgb(4, 172, 235);
    }

    &.facebook:hover {
      background: rgb(59, 89, 152);
    }

    &.email:hover {
      background: rgb(165, 178, 189);;
    }
  }
}