@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");
#stories-page {
  font: normal 14px 'Open Sans', sans-serif;
  background: white;
  padding: 20px 50px 50px;
  max-width: 1100px;
  width: 100%;
  margin: auto; }
  @media (max-width: 1300px) {
    #stories-page {
      max-width: 900px; } }
  @media (max-width: 1000px) {
    #stories-page {
      padding: 20px 30px 50px; } }

.header-container {
  margin-bottom: 25px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e8dfdf; }
  .header-container h1 {
    font-weight: 900;
    margin-bottom: 20px; }
  .header-container .header {
    display: flex; }
  .header-container .logo {
    width: 60px;
    margin-right: 20px; }
  .header-container .blog-name {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .header-container .blog-heading {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 5px; }
    .header-container .blog-heading a {
      border-bottom: 1px solid;
      padding-bottom: 1px; }
  .header-container .blog-subheading {
    color: #9c9191; }

.footer-container {
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid #e8dfdf; }
  .footer-container a:not(:last-child):after {
    content: '•';
    margin: 0 10px; }

.stories {
  min-height: calc(100vh - 260px); }
