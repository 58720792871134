#story-page {
  position: relative;
  display: flex;

  h1 {
    font-size: 40px;
    font-weight: 900;
  }

  .story-content {
    .story-block {
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      text-align: justify;
      margin: 20px 0;
      letter-spacing: 0.011rem;
      line-height: 32px;
      font-size: 21px;

      @media (max-width: 1000px) {
        font-size: 19px;
      }

      img {
        width: 100%;
        border-radius: 5px;
      }
      >img {
        margin-top: 40px;
      }
      .image-wrapper {
        text-align: center;
        width: 100%;
        margin: auto;
        max-width: 500px;
      }
      small {
        display: block;
        text-align: center;
        font-size: 12px;
        @media (min-width: 1001px) {
          height: 12px;
        }
      }
    }

    a {
      text-decoration: underline;
    }
  }

  .cover-image-wrapper {
    width: 50%;
    margin: 10px 20px 15px 0;
    float: left;

    @media (max-width: 1000px) {
      float: none;
      display: flex;
      justify-content: center;
      width: 100%;

      >div {
        width: 100%;
        max-width: 500px;
        margin: auto;
      }
    }
  }

  #publish-date {
    color: #a19796;
    margin-bottom: 10px;
  }
}

.share-buttons-desktop {
  position: sticky;
  height: fit-content;
  margin-left: -100px;
  margin-right: 50px;
  top: 100px;
  margin-top: 110px;

  @media (max-width: 1000px) {
    display: none;
  }
}

.share-buttons-mobile {
  margin-left: -5px;

  @media (min-width: 1001px) {
    display: none;
  }
}

@media (min-width: 1001px) {
  .left {
    float: left;
    margin-right: 20px !important;
  }

  .right {
    float: right;
    margin-left: 20px !important;
  }
  .mobile {
    display: none;
  }
}

@media (max-width: 1000px) {
  .left, .right {
    display: none
  }
}
