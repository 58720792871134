$color-link: #1090cb;
$link-hover: #2880a9;
$color-title: #2f3152;
$color-paragraph: #757686;
$bg-card-description: #fff;

.story-card, .story-card-no-image {
  position: relative;
  margin: auto;
  margin-bottom: 110px;

  .image-wrapper {
    width: calc(100% - 200px);
    height: 468px;
  }

  @media (max-width: 1000px) {
    margin-bottom: 50px;
    .image-wrapper {
      display: none;
    }

    .container {
      position: relative;
      width: 100%;
    }

    .content {
      width: 100%;
      height: auto;
    }
  }

  @media (min-width: 1001px) {
    .container {
      position: absolute;
      bottom: -100px;
      right: 0;
    }

    .content {
      width: 476px;
      max-height: 344px;
      float: right;
      position: relative;
    }
  }

  .content, .content-no-image {
    border-radius: 12px;
    box-sizing: border-box;
    padding: 50px 60px;
    background-color: $bg-card-description;
    box-shadow: rgba(0, 0, 0, 0.16) 0 4px 48px;
    margin-bottom: 40px;

    .publish-date {
      position: absolute;
      right: 20px;
      bottom: 20px;
      color: #9c9191;
      font-size: 12px;
    }

    h1 {
      font-size: 30px;
      font-weight: 500;
      line-height: 40px;
      margin-bottom: 12px;
      color: $color-title;
    }

    p {
      margin-top: 16px;
      line-height: 28px;
      margin-bottom: 26px;
      color: $color-paragraph;
      font-size: 16px;
    }

    a {
      font-weight: 500;
      line-height: 24px;
      color: $color-link;
      text-decoration: none;
      text-transform: uppercase;
    }

    a:hover {
      color: $link-hover;
    }
  }

  .container-no-image {
    width: 100%;
  }

  .content-no-image {
    float: none;
  }
}

.story-card-no-image {
  margin-bottom: 50px;
}

.cover-image-bg {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: center center / cover;
}

.cover-image {
  width: 100%;
  border-radius: 12px;
}

.image-credits {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(255,255,255,0.5);
  padding: 5px;
  border-radius: 8px;
  font-size: 6px;
}

